import { http } from 'wagmi'
import { mainnet, goerli } from 'wagmi/chains'
import { getDefaultConfig } from '@rainbow-me/rainbowkit'

// export const config = createConfig({
//   chains: [mainnet],
//   transports: {
//     [mainnet.id]: http()
//   }
// })

export const config = getDefaultConfig({
  appName: 'TrumpStakedETH',
  projectId: 'b6187205b37dc9d704772f16dca5b71e',
  chains: [mainnet, goerli],

  transports: {
    [goerli.id]: http('https://eth-goerli.g.alchemy.com/v2/KiGcPuDovdjrKoIDFWcY7ejSc801oQXL'),
    [mainnet.id]: http('https://eth-mainnet.g.alchemy.com/v2/EP6A_NXNsgvvMTKyz2DWegRdJTliwLT_')
    // [mainnet.id]: http('http://localhost:8545')
  }
})
